import React from 'react';

import classes from './TitleSection.module.css';
import userPrefClasses from '../UserPrefs.module.css';

const titleSection = props => {
  let title = '';
  let subtitle = '';

  if (props.userRegistered) {
    title = 'Sign Up for TCM Emails';
    subtitle = `Email address: <strong>${props.userEmail}</strong>
    Select the emails you want from the options below:`;
  } else {
    title = 'Sign Up for TCM Emails';
    subtitle = 'Select the emails you want from the options below, then submit your email address to subscribe:';
  }

  return (
    <div className={[userPrefClasses.userPrefsContainer, userPrefClasses.title].join(' ')}>
      <h2 className={classes.title}>{title}</h2>
      <p className={classes.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
    </div>
  );
};

export default titleSection;
