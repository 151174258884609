exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UserEmailSubmit_userEmailInput__3utKD {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 80%;\n  margin: auto;\n  min-height: 30em;\n  font-family: 'neueplak-regular', serif;\n}\n\n.UserEmailSubmit_userEmailInput__3utKD button:disabled {\n  background-color: #ccc;\n  cursor: not-allowed;\n}\n\n.UserEmailSubmit_userEmailInput__3utKD button {\n  background-color: #05C6DE;\n  border: none;\n  width: 171px;\n  height: 48px;\n  object-fit: contain;\n  font-size: 15pt;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);\n  color: white;\n  margin-left: 36px;\n  cursor: pointer;\n}\n\n.UserEmailSubmit_userEmailInput__3utKD input {\n  width: 100%;\n  line-height: 44px;\n  margin-right: 14px;\n  font-size: 2em;\n  background-color: #eeeeee;\n  border: 1px solid #ccc;\n  padding: 10px;\n  text-align: center;\n}\n\n.UserEmailSubmit_userEmailInput__3utKD button {\n  margin: 20px 0 0 0;\n}\n", ""]);

// exports
exports.locals = {
	"userEmailInput": "UserEmailSubmit_userEmailInput__3utKD"
};