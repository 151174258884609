exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "h2 {\n  font-family: 'neueplak-regular', serif;\n  font-size: 40px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.4;\n  position: relative;\n  margin-bottom: 50px;\n  flex-basis: 100%;\n  text-transform: uppercase;\n}\nh2:after {\n  position: absolute;\n  content: '';\n  height: 4px;\n  bottom: -17px;\n  left: 0;\n  background: #F02632;\n  clear: both;\n  width: 32px;\n}\n\nh2 div {\n  width: 32px;\n  height: 4px;\n  margin-top: 10px;\n  background-color: goldenrod;\n}\n\n.TitleSection_subtitle__3FpTg {\n  white-space: pre-line;\n  line-height: 1.6;\n  font-size: 20px;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  letter-spacing: normal;\n  margin-bottom: 48px;\n}\n@media (max-width: 767px) {\n  h2 {\n    font-size: 32px;\n    line-height: 42px;\n    padding-right: 98px;\n  }\n  .TitleSection_subtitle__3FpTg {\n    margin-bottom: 0;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"subtitle": "TitleSection_subtitle__3FpTg"
};