exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CSVSubmit_csvSubmit__2whMN {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 80%;\n  margin: auto;\n  min-height: 30em;\n  font-family: 'neueplak-regular', serif;\n}\n\n.CSVSubmit_greenButton__3itms {\n  background-color: #05C6DE;\n  border: none;\n  width: 171px;\n  height: 48px;\n  object-fit: contain;\n  font-size: 15pt;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);\n  color: white;\n  margin-left: 36px;\n  cursor: pointer;\n}\n\n.CSVSubmit_csvSubmit__2whMN button {\n  margin: 20px 0 0 0;\n}\n", ""]);

// exports
exports.locals = {
	"csvSubmit": "CSVSubmit_csvSubmit__2whMN",
	"greenButton": "CSVSubmit_greenButton__3itms"
};