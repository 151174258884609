exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UserPrefs_userPrefsContainer__20hUx {\n  max-width: 1044px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  margin-right: auto;\n  margin-left: auto;\n  padding-right: 24px;\n  padding-left: 24px;\n  width: 100%;\n  margin-bottom: -35px;\n}\n\n.UserPrefs_partnerSubBoxContainer__3rFVc {\n  display: flex;\n}\n\n.UserPrefs_hRule__1aPC2 {\n  height: 1px;\n  opacity: 0.2;\n  background-color: grey;\n}\n\n.UserPrefs_loadingMessage__hlOWc {\n  margin: auto;\n  text-align: center;\n  width: 50%;\n}\n\n.UserPrefs_checkBoxContainer__28Dq_ {\n  position: relative;\n  margin-bottom: 12px;\n  font-size: 22px;\n  -webkit-user-select: none;\n  user-select: none;\n}\n\n@media (max-width: 767px) {\n  .UserPrefs_userPrefsContainer__20hUx {\n    padding-left: 30px;\n    padding-right: 30px;\n  }\n  .UserPrefs_userPrefsContainer__20hUx {\n    margin-bottom: 0;\n  }\n}\n@media (max-width: 575px) {\n  .UserPrefs_userPrefsContainer__20hUx{\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"userPrefsContainer": "UserPrefs_userPrefsContainer__20hUx",
	"partnerSubBoxContainer": "UserPrefs_partnerSubBoxContainer__3rFVc",
	"hRule": "UserPrefs_hRule__1aPC2",
	"loadingMessage": "UserPrefs_loadingMessage__hlOWc",
	"checkBoxContainer": "UserPrefs_checkBoxContainer__28Dq_"
};